<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

body, html {
  margin: 0;
  background-color: $background_main !important;
}

header {
  height: 2rem;
  position: relative;
  z-index: 2000;
}

main {
  min-height: calc(100vh - 2rem - 2rem);
}

a {
  color: $accent_main !important;
  text-decoration: none !important;
  transition: all 0.2s;
  font-family: Manrope, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto;
  font-weight: 600;
  
  &:hover {
    color: $text_main !important;
    text-decoration: underline !important;
    transition: all 0.2s;
  }
  &:active {
    color: $text_note !important;
  }
}

footer {
  height: 2rem;
  background-color: #111;
  * {
    font-size: 14px;
    color: $text_note;
    font-family: Manrope;
  }
  a {
    color: $text_note !important;
    text-decoration: none !important;
    transition: all .2s;
    font-weight: 200 !important;
    &:hover {
      color: $accent_main !important;
      text-decoration: underline !important;
      transition: all .2s;
    }
  }
}

.hk-noise-holder {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.hk-noise-overlay {
  position: absolute;
  inset: -200%;
  width: 400%;
  height: 400%;
  opacity: .05;
  background-image: url('@/assets/noise_texture.png');
  pointer-events: none;
  animation: noice linear 8s infinite both;
}

@keyframes noice {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-10%);
  }
}

.hk-present-mode-holder {
  bottom: 2.5rem;
  right: 1rem;
  svg {
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: darken($text_note, 20%);
    transition: all 0.2s;
    &:hover {
      transition: all 0.2s;
      color: $accent_main;
    }
  }
}

.hk-presentation-mode-true {
  .hk-timer-adder-holder,
  .hk-format-toggle-holder,
  .hk-footer,
  .hk-header,
  .hk-shared-shared-btn-holder {
    transition: all 1.5s;
    opacity: 0.15 !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: $background_main;
}
::-webkit-scrollbar-thumb {
  background: $text_note;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: darken($text_note, 20%);
}

</style>
