<template>
  <div class="hk-timer-container" @click="navigateSharedTimer">
    <RouterLink :to="timer_data.isShared ? `/s/${timer_data.tag}` : '#'" :class="`hk-timer-box d-flex flex-column align-items-center ${timeStyle}`" @click="toggleControls">
      <div v-if="timer_data?.title" class="hk-timer-title-holder position-relative">
        <svg v-if="timer_data?.isShared" class="position-absolute" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M352 224H305.5c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C210.8 374.8 128 333.4 128 240c0-79.5 64.5-144 144-144h80V34.7C352 15.5 367.5 0 386.7 0c8.6 0 16.8 3.2 23.2 8.9L548.1 133.3c7.6 6.8 11.9 16.5 11.9 26.7s-4.3 19.9-11.9 26.7l-139 125.1c-5.9 5.3-13.5 8.2-21.4 8.2H384c-17.7 0-32-14.3-32-32V224zM80 96c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H400c8.8 0 16-7.2 16-16V384c0-17.7 14.3-32 32-32s32 14.3 32 32v48c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V112C0 67.8 35.8 32 80 32h48c17.7 0 32 14.3 32 32s-14.3 32-32 32H80z"></path></svg>
        <div>{{ timer_data.title }}</div>
      </div>
      <div class="d-flex hk-timer-digits">
        <h3 class="m-0">{{ t_1 }}</h3>
        <h4 class="m-0">:</h4>
        <h3 class="m-0">{{ t_2 }}</h3>
        <h4 class="m-0">:</h4>
        <h3 class="m-0">{{ t_3 }}</h3>
      </div>
    </RouterLink>

    <div :class="`hk-timer-controls hk-timer-controls-show-${controlsVisible}`">
      <div class="hk-timer-btn-container">
        <div class="hk-timer-btns d-flex align-items-center justify-content-center">
          <svg @click.stop="removeTimer" stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 3c-4.963 0-9 4.038-9 9s4.037 9 9 9 9-4.038 9-9-4.037-9-9-9zm0 16c-3.859 0-7-3.14-7-7s3.141-7 7-7 7 3.14 7 7-3.141 7-7 7zM12.707 12l2.646-2.646c.194-.194.194-.512 0-.707-.195-.194-.513-.194-.707 0l-2.646 2.646-2.646-2.647c-.195-.194-.513-.194-.707 0-.195.195-.195.513 0 .707l2.646 2.647-2.646 2.646c-.195.195-.195.513 0 .707.097.098.225.147.353.147s.256-.049.354-.146l2.646-2.647 2.646 2.646c.098.098.226.147.354.147s.256-.049.354-.146c.194-.194.194-.512 0-.707l-2.647-2.647z"></path></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/plugins/router'

export default {
  props: {
    t_1: {
      typeof: String,
      required: true,
    },
    t_2: {
      typeof: String,
      required: true,
    },
    t_3: {
      typeof: String,
      required: true,
    },
    timer_data: {
      typeof: Object,
      required: true,
    },
  },
  data() {
    return {
      controlsVisible: false,
    }
  },
  methods: {
    removeTimer() {
      this.$emit('delete')
    },
    toggleControls() {
      this.controlsVisible = !this.controlsVisible
    },
    navigateSharedTimer() {
      if (!this.timer_data.isShared) {
        return
      }
      if (this.timer_data.tag) {
        router.push(`/s/${this.timer_data.tag}`)
      }
    },
  },
  computed: {
    timeStyle() {
      return `hk-timer-done-${(this.t_1 === '00' && this.t_2 === '00' && this.t_3 === '00')}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

a {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
  &:active {
    text-decoration: none !important;
  }
  &:focus {
    outline: 2px solid $text_main !important;
  }
}

@media screen and (max-width: 699.9px) {
  .hk-timer-box {
    max-width: min(90vh, 200px);
    .hk-timer-title-holder {
      > div {
        font-size: 16px !important;
        margin-bottom: 2px;
      }
    }
  }
  .hk-timer-btns {
    right: -3.5rem !important;
  }
}
@media screen and (min-width: 700px) {
  .hk-timer-box {
    max-width: min(90vh, 400px);
  }
}

.hk-timer-box {
  background-color: rgba($text_main, .12);
  padding: .5rem .8rem;
  border-radius: .7rem;
  margin: 0.5rem 0;
  cursor: pointer;
  position: relative;
  z-index: 10;

  .hk-timer-title-holder {
    width: 100%;
    > div {
      color: $text_main;
      text-align: center;
      font-family: Manrope, system-ui, -apple-system, BlinkMacSystemFont;
      font-weight: 100;
      font-size: 18px;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    svg {
      z-index: -1;
      color: $text_note;
      opacity: 0.4;
      
      filter: blur(2px);
    }
  }

  &:hover {
    .hk-timer-title-holder > svg {
      color: $accent_main;
      filter: blur(1px);
      opacity: 0.7;
    }
    box-shadow: inset 0 0 10px $accent_main;
  }

  h3,h4 {
    color: $text_main;
    font-family: Righteous;
    // width: 1.3rem;
    text-align: center;
  }

  @media screen and (max-width: 699.9px) {
    h3, h4 {
      font-size: 1.7rem;
    }
    h3 {
      min-width: 2.1rem;
    }
    h4 {
      margin-top: -0.25rem !important;
    }
    .hk-timer-title-holder {
      svg {
        height: 2.1rem;
        width: auto;
        right: -0.6rem;
      }
    }
  }
  @media screen and (min-width: 700px) {
    h3, h4 {
      font-size: 3rem;
    }
    h3 {
      min-width: 4rem;
    }
    h4 {
      margin-top: -0.4rem !important;
    }
    .hk-timer-title-holder {
      svg {
        height: 3.2rem;
        width: auto;
        right: -0.7rem;
      }
    }
  }
}

.hk-timer-container {
  position: relative;
  z-index: 1200;

  &:hover {
    .hk-timer-controls {
      opacity: 1 !important;
    }
  }
}

.hk-timer-controls-show-true {
  opacity: 1 !important;
}

.hk-timer-controls {
  position: absolute;
  //overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .2s;
  opacity: 0;

  .hk-timer-btn-container {
    position: relative;
    height: inherit;
  }
  .hk-timer-btns {
    position: absolute;
    right: -4rem;
    width: 4rem;
    height: 100%;

    svg {
      width: 3rem;
      height: 3rem;
      color: $text_note;
      transition: all .2s;
      cursor: pointer;

      &:hover {
        color: $accent_main;
      }
      &:active {
        color: $text_main;
      }
    }
  }
}

.hk-timer-done-true {
  animation: hk-timers-up-outline 1s linear 0s infinite forwards;
  .hk-timer-digits {
    h3, h4 {
      color: $accent_main;
    }
  }
}

@keyframes hk-timers-up-outline {
  0% {
    outline: 1px solid transparent;
    box-shadow: 0 0 10px transparent, inset 0 0 10px transparent;
  }
  50% {
    outline: 3px solid $accent_main;
    box-shadow: 0 0 20px -5px $accent_main, inset 0 0 20px -5px $accent_main;
  }
  100% {
    outline: 1px solid transparent;
    box-shadow: 0 0 10px transparent, inset 0 0 10px transparent;
  }
}

</style>