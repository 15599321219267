<template>
  <div class="hk-cat-icon-unit-holder" 
    :style="{ width: `${size}px`, 
              height: `${size}px`, 
              bottom: `${pos_y}px`, 
              left: `${pos_x}px`, 
              opacity: opacity, 
              animationDelay: `${animDelay}s`, 
              animationDuration: `${animDurat}s`
            }"
  >
    <IconEnum :icon="icon" />
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import IconEnum from './IconEnum.vue'

const props = defineProps({
  icon: String,
  angle: Number,
  total: Number,
})

const k_A = 0.3 // more of a orbit length
const k_B = 1 // more of a icon size


//const win_width = window.innerWidth
const opacity = Math.random() * (0.7) + 0.4
const orbit = Math.random() * ((window.innerWidth / props.total) * k_A - (window.innerWidth * k_B)) + (window.innerWidth * k_B)
const line_deg = (360 / props.total) * (props.angle + 1)
const pos_x = Math.cos(line_deg * Math.PI / 180) * orbit
const pos_y = Math.sin(line_deg * Math.PI / 180) * orbit
const size = Math.tan((360 / props.total) * Math.PI / 180) * orbit * 0.7
const animDelay = Math.random() * (8)
const animDurat = Math.random() * (30) + 2

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

svg {
  width: 100%;
  height: 100%;
  color: #000;
  animation: wiggle 20s cubic-bezier(0.71, 0.45, 0.29, 0.57) infinite;
  // stroke: $accent_main;
  // stroke-width: 0.4px;
  // filter: drop-shadow(0 0 40px $accent_main);
}

@keyframes wiggle {
  0% {transform: rotate(0deg);}
  30% {transform: rotate(20deg);}
  60% {transform: rotate(-20deg);}
  100% {transform: rotate(0deg);}
}
@keyframes spinning {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
</style>
