import Axios from 'axios'

const apiClient = Axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? (location.origin.includes('localhost') ? `http://localhost:${process.env.VUE_APP_API_PORT}` : location.origin)
    : `http://localhost:${process.env.VUE_APP_API_PORT}`
})

export default {
  async get(url) {
    return apiClient.get(url);
  },

  async post(url, data) {
    return apiClient.post(url, data);
  },
}