<script setup>
  import TimerAdder from './components/TimerAdder.vue'
  import TimerBox from './components/TimerBox.vue';
</script>

<template>
  <header>

  </header>
  <main :class="`d-flex align-items-center justify-content-center flex-column hk-presentation-mode-${isPresentationMode}`">
    <div class="main-time-holder d-flex">
      <h1 class="m-0 hk-clk-hour">{{ timeString[0] }}</h1>
      <h1 class="m-0 hk-clk-dot-min">:</h1>
      <h1 class="m-0 hk-clk-min">{{ timeString[1] }}</h1>
      <h1 v-if="timeString.length > 2" class="m-0 hk-clk-dot-sec">:</h1>
      <h1 v-if="timeString.length > 2" class="m-0 hk-clk-sec">{{ timeString[2] }}</h1>
    </div>
    <div>

    </div>
    <div class="mb-4 hk-format-toggle-holder">
      <button @click="toggleFormat">{{ format === 'WEIRD' ? 'Make it useful' : 'Make it the right way'}}</button>
    </div>

    <div class="mb-4">
      <div v-for="(timer, i) in timers" :key="`${i}-${timer?.tag !== '' ? timer.tag : 'local'}`">
        <TimerBox v-if="timer.display" 
          :t_1="timer.display[0]" :t_2="timer.display[1]" :t_3="timer.display[2]" :timer_data="timer"
          @delete="removeTimer(i)"
        />
      </div>
    </div>
    
    <TimerAdder type="timer" @create="addTimer"/>
    <TimerAdder type="countdown" @create="addTimer"/>
    <div class="mb-4"></div>

    <div>
      <div class="position-absolute hk-present-mode-holder" @click="togglePresentation">
        <template v-if="!isPresentationMode">
          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2 3h20"></path><path d="M21 3v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3"></path><path d="m7 21 5-5 5 5"></path></svg>
        </template>
        <template v-if="isPresentationMode">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>
        </template>
      </div>
    </div>

  </main>
  <footer :class="`d-flex justify-content-center align-items-center hk-presentation-mode-${isPresentationMode}`">
    <div class="d-flex justify-content-center align-items-center hk-footer">
      <p class="me-2 my-0">Made with 🌮 by</p>
      <p class="mx-0 my-0">|</p>
      <a class="mx-2 my-0" href="https://hkubo.sk" target="_blank">HKubo</a>
      <p class="mx-0 my-0">{{ yearString }}</p>
    </div>
  </footer>

  <div class="hk-noise-holder" v-if="!isIOS">
    <div class="hk-noise-overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'DashboardView',
  data() {
    return {
      yearString: '2023',
      timeString: ['0', '0'],
      format: 'WEIRD',
      timers: [],
      isPresentationMode: false,
    }
  },
  computed: {
    isIOS() {
      if (navigator) {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent)
      }
      return false
    }
  },
  methods: {
    updateTime() {
      const time = new Date()
      if (this.format === 'WEIRD') {
        this.timeString = [
          ((time.getHours() * 60) + time.getMinutes()).toString().padStart(2, 0),
          time.getSeconds().toString().padStart(2, '0')
        ]
      } else {
        this.timeString = [
          time.getHours().toString().padStart(2, '0'),
          time.getMinutes().toString().padStart(2, '0'),
          time.getSeconds().toString().padStart(2, '0')
        ]
      }

      // timers update
      for (let i = 0; i < this.timers.length; i++) {
        const target = new Date(this.timers[i].end)
        let hours = (target.getTime() - time.getTime())/ 1000 /60 /60
        let minutes = (target.getTime() - time.getTime())/ 1000 /60 %60
        let seconds = (target.getTime() - time.getTime())/ 1000 %60

        if (hours <= 0) {
          hours = 0
        }
        if (minutes <= 0) {
          minutes = 0
        }
        if (seconds <= 0) {
          seconds = 0
        }

        this.timers[i].display = [
          Math.floor(hours).toString().padStart(2, '0'), 
          Math.floor(minutes).toString().padStart(2, '0'), 
          Math.floor(seconds).toString().padStart(2, '0')
        ]
      }
    },
    toggleFormat() {
      this.format = (this.format === 'WEIRD') ? 'NORMAL' : 'WEIRD'
      localStorage.setItem('format', this.format)
      this.timeString = []
      this.updateTime()
    },
    addTimer(timer) {
      // if (this.timers.length > 4) {
      //   return
      // }

      this.timers.push(timer)
      this.timers.sort((a, b) => {
        const a_t = new Date(a.end)
        const b_t = new Date(b.end)
        return a_t.getTime() - b_t.getTime() 
      })

      localStorage.setItem('timers', JSON.stringify(this.timers))
    },
    removeTimer(index) {
      this.timers.splice(index, 1)
      localStorage.setItem('timers', JSON.stringify(this.timers))
    },
    togglePresentation() {
      this.isPresentationMode = !this.isPresentationMode
    },
  },
  mounted() {
    document.title = "Current Time | kolkojeminut.sk"

    if (localStorage.getItem('timers')) {
      this.timers = JSON.parse(localStorage.getItem('timers'))
    }
    if (localStorage.getItem('format')) {
      this.format = localStorage.getItem('format')
    }

    this.yearString = (new Date()).getFullYear()
    this.updateTime()

    setInterval(() => {
      this.updateTime()
    }, 1000)
  },
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.main-time-holder {
  h1 {
    color: $accent_main;
    font-family: Righteous;
    text-align: center;
    font-weight: 900;
  }
  .hk-clk-sec,
  .hk-clk-dot-sec {
    opacity: 0.55;
  }
  .hk-clk-dot-sec,
  .hk-clk-dot-min{
    margin-top: -1rem !important;
  }

  @media screen and (max-width: 699.9px) {
    h1 {
      font-size: 4rem !important;
      padding-bottom: 2rem;
    }
    .hk-clk-hour,
    .hk-clk-min,
    .hk-clk-sec {
      min-width: 5rem;
    }
    .hk-clk-dot-sec,
    .hk-clk-dot-min{
      margin-top: -0.6rem !important;
    }
  }
  @media screen and (min-width: 700px) {
    h1 {
      font-size: 10rem !important;
    }
    .hk-clk-hour,
    .hk-clk-min,
    .hk-clk-sec {
      min-width: 13rem;
    }
  }
}

</style>
