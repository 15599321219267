<template>
  <div class="hk-timer-adder-holder d-flex justify-content-center col-12 px-2 col-md-6 col-xl-4">

    <div v-if="!active" class="d-flex justify-content-between col-8 col-md-6 col-xl-5 align-items-center hk-timer-adder-inactive" @click="handleClick">
      <div class="hk-timer-adder-icon-holder d-flex align-items-center ms-1">
        <svg v-if="type === 'timer'" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>
        <svg v-if="type === 'countdown'" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M336 292v24c0 6.6-5.4 12-12 12h-76v76c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-76h-76c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h76v-76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v76h76c6.6 0 12 5.4 12 12zm112-180v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>
      </div>
      <div>
        <p v-if="type === 'timer'">Add timer</p>
        <p v-if="type === 'countdown'">Add countdown</p>
      </div>
      <div class="px-2 me-1"></div>
    </div>

    <div v-else class="col-12 hk-timer-adder-active">
      <input class="hk-timer-adder-title-input col-12 px-2 py-1 mb-2" type="text" v-model="title" placeholder="Timer name">

      <label>Time</label>
      <div v-if="type === 'timer'" class="d-flex justify-content-between col-12">
        <div class="d-flex hk-timer-custom-input-holder col-9">
          <input type="number" min="0" placeholder="min" class="col-5" v-model="inputTimer[0]">
          <p class="ma-0 d-flex align-center col-1 justify-content-center">:</p>
          <input type="number" min="0" placeholder="sec" class="col-5" v-model="inputTimer[1]">
        </div>
        <button class="secondary" @click="handleClick">Cancel</button>
      </div>
      
      <div v-if="type === 'countdown'" class="d-flex justify-content-between col-12">
        <div class="col-9 d-flex pe-4">
          <input type="datetime-local" class="col-12 px-2 py-1" v-model="inputCountdown">
        </div>
        <button class="secondary" @click="handleClick">Cancel</button>
      </div>
      
      <div class="d-flex slign-items-center justify-content-between mt-4">
        <button @click="setTimer(false)">Create Shared Timer</button>
        <button class="cta" @click="setTimer(true)">Set Timer</button>
      </div>

    </div>

  </div>
</template>

<script>
import apiService from '@/services/apiService'
import router from '../plugins/router'

export default {
  emits: ['create'],
  props: {
    type: {
      typeof: String,
      required: true,
    }
  },
  data() {
    return {
      active: false,
      inputCountdown: null,
      inputTimer: [],
      title: '',
    }
  },
  methods: {
    handleClick() {
      this.active = !this.active
    },
    setTimer(timerLocal) {
      let targetDate = new Date()
      if (this.inputTimer.length > 0) {
        if (this.inputTimer[0] && this.inputTimer[1]) {
          targetDate.setTime(targetDate.getTime() + ((Number(this.inputTimer[0])*60 + Number(this.inputTimer[1])) * 1000))
        } else if (this.inputTimer[0]) {
          targetDate.setTime(targetDate.getTime() + ((Number(this.inputTimer[0])*60) * 1000))
        } else if (this.inputTimer[1]) {
          targetDate.setTime(targetDate.getTime() + (Number(this.inputTimer[1]) * 1000))
        } else {
          return
        }
      }
      else {
        if (this.inputCountdown !== null) {
          targetDate = new Date(this.inputCountdown)
        } else {
          return
        }
      }

      const timer = {type: this.type, isShared: !timerLocal}
      const end = targetDate.toISOString()
      timer.end = end
      timer.title = this.title

      // duration check
      const now = new Date()
      const target = new Date(end)

      if (target.getTime() - now.getTime() <= 1000) {
        return
      }

      if (!timerLocal) {
        const payload = {
          title: this.title,
          countdown: end,
          type: this.type,
        }
        apiService.post('/api/timer', payload)
          .then(response => {
            if (response.status === 200) {
              timer.tag = response.data['tag']
              // clear
              this.title = ''
              this.inputCountdown = null
              this.inputTimer = []

              this.$emit('create', timer)
              this.handleClick()
              return
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              router.push('/error/not-found')
            } else if (error.response.status === 429){
              router.push('/error/limiter')
            } else {
              router.push('/error/fetch-error')
            }
          })
      } else {
        // clear
        this.title = ''
        this.inputCountdown = null
        this.inputTimer = []
        
        this.$emit('create', timer)
        this.handleClick()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.hk-timer-adder-holder {
  margin: .4rem 0;
  cursor: pointer;

  .hk-timer-adder-inactive {
    padding: 0.3rem 0.2rem;
    border: 2px solid $text_note;
    border-radius: 0.7rem;
    transition: all 0.2s;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    color: $text_note;
    padding-left: 0.1rem;
    transition: all 0.2s;
  }

  p {
    padding: 0 0.5rem;
    margin: 0;
    text-align: left;
    color: $text_note;
    font-family: Manrope;
    transition: all 0.2s;
  }

  &:hover{
    svg, p {
      color: $accent_main;
    }
    div {
      border-color: $accent_main;
    }
  }
  &:active {
    svg, p {
      color: $text_main;
    }
    div {
      border-color: $text_main;
    }
  }
}

.hk-timer-adder-active {
  background-color: rgba($text_main, .12);
  padding: .5rem;
  border-radius: 1rem;

  label {
    color: $text_note;
    font-family: Manrope, system-ui, -apple-system, BlinkMacSystemFont;
  }

  input {
    background-color: rgba($text_main, .12);
    border-radius: 6px;
    border: none;
    outline: none;
    color: $text_main;
    accent-color: $text_main;
    color-scheme: dark;
    font-family: Manrope;
    letter-spacing: 1px;
    font-size: 1rem;
    padding-left: 0.3rem;

    &.hk-timer-adder-title-input {
      font-size: 18px;
    }
  }
  button {
    padding: 0.3rem 1rem;
  }
  .hk-btn-secondary {
    padding: 0.3rem 0.6rem;
    border-color: $text_note;
    margin-left: 1rem !important;
    color: $text_note;
    transition: all 0.2s;
    &:hover {
      border-color: $text_main;
      color: $text_main;
    }
  }
}

.hk-timer-custom-input-holder {
  p {
    color: $text_main;
    cursor: default;
    &:hover {
      color: $text_main !important;
    }
  }
  input {
    text-align: center;
    &::placeholder {
      color: $text_main;
    }
  }
}

</style>