import { createApp } from 'vue'
import App from './App.vue'
import router from '@/plugins/router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/styles/main.scss'
import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
import RelativeTime from 'dayjs/plugin/relativeTime'
import Calendar from 'dayjs/plugin/calendar'
import UpdateLocale from 'dayjs/plugin/updateLocale'
import Duration from 'dayjs/plugin/duration'

dayjs.locale('en-gb')
dayjs.extend(RelativeTime)
dayjs.extend(Calendar)
dayjs.extend(UpdateLocale)
dayjs.extend(Duration)
dayjs.updateLocale('en-gb', {
  calendar: {
    lastDay: '[Yesterday at] HH:mm',
    sameDay: '[Today at] HH:mm',
    nextDay: '[Tomorrow at] HH:mm',
    lastWeek: '[last] dddd [at] HH:mm',
    nextWeek: 'dddd [at] HH:mm',
    sameElse: 'DD.MM.YYYY [at] HH:mm'
  }
})

const app = createApp(App)
app.config.globalProperties.$dayjs = dayjs
app.use(router)

app.mount('#app')
