<template>
  <header></header>

  <main class="d-flex justify-content-center align-items-center">
    <section class="px-2 col-12 col-md-8 col-md-lg-7 col-xl-6">

      <div v-if="$route.params.errorCode === 'not-found'" class="err-content-holder">
        <h1>404</h1>
        <div class="err-content-title mb-2">Not Found</div>
        <div class="err-content-desc">The site you are looking for does not exists.</div>
        <div class="err-content-note">Bummer. Double check the source of the link.</div>
        <button class="mt-4" @click="toHomePage">Back to main page</button>
      </div>

      <div v-else-if="$route.params.errorCode === 'limiter'" class="err-content-holder">
        <h1>Calm down</h1>
        <div class="err-content-title mb-2">Too many requests</div>
        <div class="err-content-desc">You are trying to make too many requests.</div>
        <div class="err-content-note">Try again later.</div>
        <button class="mt-4" @click="toHomePage">Back to main page</button>
      </div>

      <div v-else class="err-content-holder">
        <h1>Wait whaat?</h1>
        <div class="err-content-title mb-2">Error occured</div>
        <div class="err-content-desc">Unknown error just happened.</div>
        <div class="err-content-note">Try not to do what you just did.</div>
        <button class="mt-4" @click="toHomePage">Back to main page</button>
      </div>

    </section>
  </main>

  <footer :class="`d-flex justify-content-center align-items-center hk-presentation-mode-${isPresentationMode}`">
    <div class="d-flex justify-content-center align-items-center hk-footer">
      <p class="me-2 my-0">Made with 🌮 by</p>
      <p class="mx-0 my-0">|</p>
      <a class="mx-2 my-0" href="https://hkubo.sk" target="_blank">HKubo</a>
      <p class="mx-0 my-0">{{ yearString }}</p>
    </div>
  </footer>

  <div class="hk-noise-holder" v-if="!isIOS">
    <div class="hk-noise-overlay"></div>
  </div>
</template>

<script>
import router from './plugins/router'

export default {
  name: 'DashboardView',
  data() {
    return {
      yearString: '2024',
      isPresentationMode: false,
    }
  },
  computed: {
    isIOS() {
      if (navigator) {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent)
      }
      return false
    },
  },
  methods: {
    toHomePage() {
      router.push('/')
    },
  },
  mounted() {
    this.yearString = (new Date()).getFullYear()
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

main section {
  margin-bottom: 20vh;
}

.err-content-holder {
  color: $text_main;

  h1 {
    font-family: Righteous, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto;
    color: $accent_main;
    font-size: 80px;
    filter: drop-shadow(10px 1px 3px lighten($background_main, 10%));
  }

  div {
    font-family: Manrope;
  }

  .err-content-title {
    font-size: 40px;
  }
  .err-content-desc {

  }
  .err-content-note {
    color: $text_note;
    font-style: italic;
  }
}

</style>
