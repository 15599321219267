import { createRouter, createWebHistory } from 'vue-router'

import DashboardView from '../DashboardView.vue'
import SharedTimerView from '../SharedTimerView.vue'
import ErrorView from '@/ErrorView.vue'


const routes = [
  { 
    path: '/',
    name: 'dashboard',
    component: DashboardView,
  },
  { 
    path: '/s/:timerTag',
    name: 'sharedTimer',
    component: SharedTimerView,
  },
  {
    path: '/error/:errorCode',
    name: 'error',
    component: ErrorView,
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    redirect: '/error/not-found',
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

export default router
