<script setup>
  import CategoryIcon from './CategoryIcon.vue'
  import IconEnum from './IconEnum.vue';
</script>

<template>
  <div class="position-aboslute">
    <div class="hk-icon-cat-holder" :style="{opacity: opacity_koef}">
      <CategoryIcon 
      v-for="index in Array.from(Array(count).keys())" class="hk-icon-cat-unit"
      :key="index" :angle="index" :total="count" :icon="category"
      />
    </div>

    <div class="hk-icon-megicon-holder hk-shared-shared-btn-holder d-flex align-items-center justify-content-center">
      <IconEnum :icon="category" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      typeof: String,
      required: true,
    },
    count: {
      typeof: Number,
      required: true,
    },
    opacity_koef: {
      typeof: Number,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.hk-icon-cat-holder {
  position: relative;
}
.hk-icon-cat-unit {
  position: absolute;
}
.hk-icon-megicon-holder {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 10vh;
  left: 0;

  svg {
    width: 90%;
    height: 100%;
    filter: drop-shadow(0 0 20px black);
    opacity: 0.8;
    color: rgba(black, 0.8);
  }
}
</style>
