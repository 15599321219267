<script setup>
  import CategoryEffect from './components/CategoryEffect.vue'
</script>

<template>

  <header :class="`d-flex justify-content-center hk-presentation-mode-${isPresentationMode}`">
    <div class="col-12 col-md-9 col-lg-7 col-xl-6 hk-header">
      <RouterLink to="/" class="d-flex align-items-center px-3 py-1">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
        <div class="ms-2">Back</div>
      </RouterLink>
    </div>
  </header>

  <main :class="`d-flex align-items-center justify-content-center position-relative flex-column hk-presentation-mode-${isPresentationMode}`">
    <div class="hk-shared-timer-container">
      <div v-if="timer.title" class="d-flex justify-content-center">
        <div class="hk-shared-title">{{ timer.title }}</div>
      </div>
      
      <div v-if="isLoading">
        <div class="hk-shared-loading-title">
          <svg :style="{animationDuration: '1.2s', opacity: '0.3'}" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z"></path></svg>
          <svg :style="{animationDuration: '1.7s', opacity: '1'}" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
        </div>
      </div>
      <div v-else>
        <div class="w-100">
          <div class="hk-shared-back-holder"></div>
        </div>
        <div class="hk-shared-timer-timer d-flex mb-2">
          <h1 class="m-0">{{ time[0] }}</h1>
          <h2 class="m-0">:</h2>
          <h1 class="m-0">{{ time[1] }}</h1>
          <h2 class="m-0">:</h2>
          <h1 class="m-0">{{ time[2] }}</h1>
        </div>

        <div v-if="timer.type === 'timer'" class="hk-shared-loadbar-container d-flex justify-content-center">
          <div class="hk-shared-loadbar-holder position-relative d-flex justify-content-end">
            <div class="position-absolute hk-shared-loadbar-bg"></div>
            <div class="position-absolute hk-shared-loadbar-fg" :style="{width: `${completion}%`}"></div>
          </div>
        </div>

        <div class="hk-shared-timer-target-holder d-flex justify-content-center w-100">
          <div>{{ timerTimeHuman }}</div>
        </div>
      </div>
    </div>

    <div v-if="!isInLocalMemory && !importRequested" class="hk-shared-shared-btn-holder hk-shared-import-btn-holder mb-4 d-flex flex-column align-items-center">
      <div class="mb-2">Save this timer to your dashboard</div>
      <button @click="importTimer" class="d-flex align-items-center cta">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m12 18 4-5h-3V2h-2v11H8z"></path><path d="M19 9h-4v2h4v9H5v-9h4V9H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2z"></path></svg>
        <div class="ms-2">Save</div>
      </button>
    </div>
    <div v-else class="hk-shared-shared-btn-holder mt-4">
      <div class="hk-shared-link-title mb-2">Share this timer</div>
      <button @click="copySharedLink" class="d-flex align-items-center">
        <div class="me-3">Copy Link</div>
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z"></path></svg>
      </button>
      <div :class="`hk-shared-link-note mt-1 d-flex align-items-center justify-content-center show-link-${showLinkNote}`">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
        <div class="mx-1">Link Copied...</div>
      </div>
    </div>

    <div>
      <div class="position-absolute hk-present-mode-holder" @click="togglePresentation">
        <template v-if="!isPresentationMode">
          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2 3h20"></path><path d="M21 3v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3"></path><path d="m7 21 5-5 5 5"></path></svg>
        </template>
        <template v-if="isPresentationMode">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>
        </template>
      </div>
    </div>
  </main>

  <div :class="`position-absolute hk-shared-category-type d-flex justify-content-center align-items-center hk-presentation-mode-${isPresentationMode}`">
    <div class="hk-shared-category-center">
      <!--<CategoryEffect v-if="timer?.category" :category="timer.category" :count="8" :opacity_koef="0.1"/>-->
      <CategoryEffect v-if="timer?.category" :category="timer.category" :count="20" :opacity_koef="1"/>
      <CategoryEffect v-else :category="'null'" :count="20" :opacity_koef="1"/>
    </div>
  </div>

  <footer :class="`d-flex justify-content-center align-items-center position-relative hk-presentation-mode-${isPresentationMode}`">
    <div class="d-flex justify-content-center align-items-center hk-footer">
      <p class="me-2 my-0">Made with 🌮 by</p>
      <p class="mx-0 my-0">|</p>
      <a class="mx-2 my-0" href="https://hkubo.sk" target="_blank">HKubo</a>
      <p class="mx-0 my-0">{{ yearString }}</p>
    </div>
  </footer>

  <div class="hk-noise-holder" v-if="!isIOS">
    <div class="hk-noise-overlay"></div>
  </div>
</template>

<script>
import apiService from '@/services/apiService'
import router from './plugins/router'
import dayjs from 'dayjs'

export default {
  name: 'DashboardView',
  data() {
    return {
      timer: {},
      yearString: '2023',
      isPresentationMode: false,
      time: ['00', '00', '00'],
      completion: 10,
      showLinkNote: false,
      isLoading: true,
      importRequested: false,
      callback_1: null,
      callback_2: null,
    }
  },
  computed: {
    isIOS() {
      if (navigator) {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent)
      }
      return false
    },
    timerTimeHuman() {
      return dayjs(this.timer.countdown).isBefore(dayjs())
        ? `Timer ended ${dayjs(this.timer.countdown).calendar()}`
        : `Timer ends ${dayjs(this.timer.countdown).calendar()}`
    },
    isInLocalMemory() {
      let timers = []
      if (localStorage.getItem('timers')) {
        timers = JSON.parse(localStorage.getItem('timers'))
      }
      const found = timers.find((timer) => timer.tag === this.$route.params.timerTag)
      return !!found && !this.importRequested
    },
  },
  methods: {
    importTimer() {
      const newTimer = this.timer
      newTimer['isShared'] = true
      newTimer['end'] = this.timer.countdown

      let timers = []
      if (localStorage.getItem('timers')) {
        timers = JSON.parse(localStorage.getItem('timers'))
      }
      const found = timers.find((timer) => timer.tag === this.$route.params.timerTag)
      if (found) {
        return
      }

      timers.push(newTimer)
      timers.sort((a, b) => {
        const a_t = new Date(a.end)
        const b_t = new Date(b.end)
        return a_t.getTime() - b_t.getTime() 
      })

      this.importRequested = true
      localStorage.setItem('timers', JSON.stringify(timers))
    },
    copySharedLink() {
      navigator.clipboard.writeText(window.location.href);
      this.showLinkNote = true;
      setTimeout(() => {
        this.showLinkNote = false;
      }, 3000)
    },
    togglePresentation() {
      this.isPresentationMode = !this.isPresentationMode
    },
    getTimerData() {
      apiService.get(`/api/timer/${this.$route.params.timerTag}`)
        .then(response => {
          if (response.status === 200) {
            this.timer = response.data
            this.calcTimer()
            document.title = `${response.data?.title ? response.data.title : 'Shared timer'} | kolkojeminut.sk`
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            router.push('/error/not-found')
          } else if (error.response.status === 429){
            router.push('/error/limiter')
          } else {
            router.push('/error/fetch-error')
          }
        })
    },
    updateTimer() {
      setInterval(() => {
        this.calcTimer()
      }, 1000)
    },
    calcTimer() {
      if (this.timer.countdown) {
        const now = new Date()
        const target = new Date(this.timer.countdown)
        let hours = (target.getTime() - now.getTime())/ 1000 /60 /60
        let minutes = (target.getTime() - now.getTime())/ 1000 /60 %60
        let seconds = (target.getTime() - now.getTime())/ 1000 %60
        
        if (hours <= 0) {
          hours = 0
        }
        if (minutes <= 0) {
          minutes = 0
        }
        if (seconds <= 0) {
          seconds = 0
        }
        
        this.time = [
          Math.floor(hours).toString().padStart(2, '0'), 
          Math.floor(minutes).toString().padStart(2, '0'), 
          Math.floor(seconds).toString().padStart(2, '0')
        ]

        // completion
        const start = new Date(this.timer.created_on)
        const total = target.getTime() - start.getTime()
        const remains = target.getTime() - now.getTime()
        const done = remains / total * 100
        if (done >= 0) {
          this.completion = done
        } else {
          this.completion = 0
        }

        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.yearString = (new Date()).getFullYear()
    this.getTimerData()
    this.updateTimer()

    this.callback_1 = setTimeout(() => {
      this.getTimerData()
    }, 5000)
    this.callback_2 = setTimeout(() => {
      this.getTimerData()
    }, 8000)
  },
  beforeUnmount() {
    clearTimeout(this.callback_1)
    clearTimeout(this.callback_2)
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

header, main, footer{
  z-index: 500;
}

header {
  a {
    width: fit-content;
  }
}

.hk-shared-timer-timer {
  padding-bottom: 2vh;
  h1 {
    color: $text_main;
    text-align: center;
    font-weight: 600;
    font-family: Righteous;
  }
  h2 {
    color: $text_main;
  }
}

.hk-shared-timer-target-holder {
  div {
    color: $text_note;
    font-family: Manrope;
  }
}

.hk-shared-back-holder {
  svg {
    color: $accent_main;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.hk-shared-loading-title {
  margin-bottom: 5vh;
  font-family: Manrope;
  position: relative;
  width: 40px;
  height: 40px;
  svg {
    position: absolute;
    color: $text_main;
    width: 40px;
    height: 40px;
    animation: spinning 1.2s cubic-bezier(0.71, 0.45, 0.29, 0.57) infinite;
  }
}

@keyframes spinning {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.hk-shared-link-title {
  color: $text_note;
  font-family: Manrope;
  text-align: center;
  font-size: 18px;
}
.hk-shared-link-note {
  opacity: 0;
  color: $text_note;
  text-align: center;
  transition: all 1s;
  svg {
    width: 16px;
    height: 16px;
  }
}
.show-link-true {
  &.hk-shared-link-note {
    opacity: 1 !important;
    transition: all 0.2s;
  }
}


.hk-shared-title {
  text-align: center;
  color: $text_note;
  font-family: Righteous;
  font-weight: 100;
  margin-bottom: 1rem;
}

.hk-shared-shared-btn-holder {
  margin-bottom: 8vh;
}

@media screen and (max-width: 699.9px) {
  .hk-shared-timer-timer {
    h1 {
      font-size: 3.5rem !important;
      min-width: 5rem;
    }
    h2 {
      font-size: 3rem !important;
    }
  }
  .hk-shared-title {
    font-size: 1.8rem;
    line-height: 2rem;
    max-width: 90vw;
  }
  .hk-shared-loading-title {
    font-size: 1.4rem;
  }
  .hk-shared-category-center {
    margin-bottom: 13rem;
  }
}
@media screen and (min-width: 700px) {
  .hk-shared-timer-timer {
    h1 {
      font-size: 8rem !important;
      min-width: 12rem;
    }
    h2 {
      font-size: 7rem !important;
    }
  }
  .hk-shared-title {
    font-size: 3rem;
    line-height: 3.2rem;
    max-width: min(500px, 60vw);
  }
  .hk-shared-loading-title {
    font-size: 2.4rem;
  }
  .hk-shared-category-center {
    margin-bottom: 11rem;
  }
}

.hk-shared-category-type {
  pointer-events: none;
  background: linear-gradient(lighten($background_main, 5%), darken($background_main, 10%)) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  .hk-shared-category-center {
    width: 1px;
    height: 1px;
  }
}

.hk-shared-import-btn-holder {
  > div {
    color: $text_main;
    font-family: Manrope;
    font-size: 18px;
  }
  button {
    width: fit-content;
  }
}

.hk-shared-timer-container {
  padding: 2vh 2.5vh;
  margin-bottom: 10vh;
  border-radius: 14px;
}

.hk-shared-loadbar-container {
  height: 2vh;
  margin-bottom: 2vh;
  width: 100%;
}

.hk-shared-loadbar-holder {
  height: 100%;
  width: 90%;
  div {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }
  .hk-shared-loadbar-bg {
    background-color: darken($text_note, 40%);
  }
  .hk-shared-loadbar-fg {
    background-color: $accent_main;
    transition: all 1.1s ease-in-out;
  }
}

</style>
